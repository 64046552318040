<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="匹配星广汇红人" :append-to-body="true">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="120px">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="红人（KOL）" prop="nickname">
              <el-input v-model="formData.nickname" placeholder="请输入红人" clearable :style="{width: '100%'}"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="匹配红人">
              <AdArtistSearch @handleSelect="chooseAdArtist"/>
              <!--              <el-input v-model="formData.ad_nickname" placeholder="请输入匹配红人" clearable :style="{width: '100%'}"></el-input>-->
            </el-form-item>
          </el-col>
        </el-row>
        <br/>
        <el-row :gutter="15">
          <el-col :span="11" style="text-align: right">
            <span style="font-weight: 800;margin-left: 10px">星象仪红人：</span>
            <span>{{ this.formData.nickname || '--' }}</span>
          </el-col>
          <el-col :span="2" style="text-align: center">
            <i class="el-icon-sort" style="font-size: 1.6rem"></i>
          </el-col>
          <el-col :span="11" style="text-align: left">
            <span style="font-weight: 800;margin-left: 10px">星广汇红人：</span>
            <span>{{ this.formData.ad_nickname || '--' }}</span>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AdArtistSearch from "@/components/artist/AdArtistSearch";

export default {
  name: "ArtistMatch",
  inheritAttrs: false,
  components: { AdArtistSearch },
  props: {
    id: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      formData: {
        artist_id: undefined,
        nickname: undefined,
        ad_nickname: undefined,
        ad_artist_id: undefined,
      },
      rules: {
        nickname: [{
          required: true,
          message: '请输入红人',
          trigger: 'blur'
        }],
        ad_nickname: [{
          required: true,
          message: '请输入匹配红人',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    onOpen() {
      this.formData = {
        artist_id: undefined,
        nickname: undefined,
        ad_nickname: undefined,
        ad_artist_id: undefined,
      }
      this.getInfo()
    },
    onClose() {
      this.$refs['elForm'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return

        // this.close()
        this.saveArtistCompare(this.formData)
      })
    },
    async getInfo() {
      if (this.id) {
        let { info } = await this.$api.getArtistInfo(this.id)
        if (info) {
          this.formData.artist_id = info.id
          this.formData.nickname = info.nickname
        } else {
          this.$notify.error('红人（KOL）不存在！！！');
        }
      }
    },
    chooseAdArtist(item) {
      if (item) {
        this.formData.ad_artist_id = item.id
        this.formData.ad_nickname = item.nickname
      } else {
        this.searchCondition.ad_artist_id = null
        this.searchCondition.ad_nickname = null

      }
    },
    async saveArtistCompare(row) {
      let id = await this.$api.saveArtistCompare({
        artist_id: row.artist_id,
        nickname: row.nickname,
        ad_artist_id: row.ad_artist_id,
        ad_nickname: row.ad_nickname
      })
      if (id) {
        this.$notify.success(`成功匹配：${ row.nickname } = ${ row.ad_nickname }`)
        this.close()
      }
    },
  }
}

</script>

<style scoped>

</style>
