<template>
    <div style="padding: 5px 10px">
        <div>
            <span style="font-weight: 800;font-size: 1.1em">{{ title }}</span>
        </div>
        <div v-if="quoteInfo&&quoteInfo.multi">
            <el-card style="margin-bottom: 5px" v-if="dateType==='object'&&quoteInfo.multi">
                <div>
                    <span style="font-weight: 800">品类/场次/直播费用</span>
                    <el-button type="text" icon="el-icon-plus" style="float: right" @click="addMultiRow"
                               :disabled="!editPrice">
                        添加
                    </el-button>
                </div>
                <el-table :data="quoteInfo.multi">
                    <el-table-column label="品类" prop="kind" align="center" min-width="100">
                        <template slot-scope="{row,column}">
                            <el-input v-model="row[column.property]" :disabled="!editPrice" clearable/>
                        </template>
                    </el-table-column>
                    <el-table-column label="场次" prop="sessions" align="center" min-width="100">
                        <template slot-scope="{row,column}">
                            <el-input v-model="row[column.property]" :disabled="!editPrice" clearable/>
                        </template>
                    </el-table-column>
                    <el-table-column label="直播费用" prop="lb_fee" align="center" min-width="160">
                        <template slot-scope="{row,column}">
                            <el-input v-model="row[column.property]" :disabled="!editPrice" clearable/>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" min-width="60">
                        <template slot-scope="{$index}">
                            <el-button type="text" icon="el-icon-delete" @click="delMultiRow($index)"
                                       :disabled="!editPrice"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </div>

        <el-card v-if="dateType==='object'">
            <div>
                <span style="font-weight: 800">报价明细</span>
            </div>
            <div v-if="quoteInfo.single">
                <div v-for="(item,index) in quoteInfo.single" :key="index">
                    <div style="display: flex">
                        <span class="is-label" style="flex: 2;">{{ item.title }}</span>
                        <div style="flex: 3">
                            <el-input :placeholder="item.placeholder||'请输入当月报价'" v-model="item.val"
                                      oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled="!editPrice" clearable>
                            </el-input>
                        </div>
                        <div style="flex: 5;margin-left: 5px">
                            <el-input placeholder="请输入备注信息" v-model="item.remark" :disabled="!editPrice" clearable>
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card v-else>
            <div>
                <span style="font-weight: 800">报价明细</span>
            </div>
            <div v-for="(item,index) in quoteInfo" :key="index">
                <div style="display: flex">
                    <span class="is-label" style="flex: 2;">{{ item.title }}</span>
                    <div style="flex: 3">
                        <el-input :placeholder="item.placeholder||'请输入下月预估价格'"  v-model="item.val"
                                  oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled="!editPrice" clearable>
                        </el-input>
                    </div>
                    <div style="flex: 5;margin-left: 5px">
                        <el-input placeholder="请输入备注信息" v-model="item.remark" :disabled="!editPrice" clearable>
                        </el-input>
                    </div>
                </div>
            </div>
        </el-card>

    </div>
</template>

<script>

  export default {
    name: 'AdjustPricePanel',
    props: {
      title: {
        type: String,
        default() {
          return '报价'
        }
      },
      //报价信息-Array
      quoteInfo: {
        type: [Array, Object],
        default() {
          return { multi: [], single: [] }
          //multi: 报价信息：KOL = n:1
          //single: 报价信息：KOL = 1:1
          //表格导出时候需要区分，用于行合并
        }
      },
      editPrice: {
        type: Boolean,
        default() {
          return false
        }
      },
      platformType: {
        type: String,
        default() {
          return 'ONLINE'
        }
      }
    },
    computed: {
      dateType() {
        if (this.quoteInfo) {
          if (typeof this.quoteInfo === 'object') {
            if (this.quoteInfo instanceof Array) {
              return 'array'
            } else {
              return 'object'
            }
          }
        }
        return null
      }
    },
    methods: {
      addMultiRow() {
        if (!this.quoteInfo.multi)
          this.$set(this.formData, 'multi', [])

        this.quoteInfo.multi.push({ kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金' })
      },
      delMultiRow(index) {
        if (!this.quoteInfo.multi)
          this.$set(this.quoteInfo, 'multi', [])

        this.quoteInfo.multi.splice(index, 1)
      }
    }

  }
</script>

<style scoped>

</style>