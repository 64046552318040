<template>
  <div>
    <el-form v-model="formData" size="mini">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label=""
                        class="edit-box">
            <div>
              <span style="font-weight: 800">当月价格</span>
            </div>
            <div style="display: flex">
              <div style="flex: 10">
                <PriceGrids :rows="formData.current_quote"/>
              </div>
              <el-button type="primary" :plain="!(formData.quote_source==='current_quote')" icon="el-icon-circle-check"
                         class="check-btn"
                         @click="selectQuote(formData.current_quote,'current_quote')"></el-button>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="" class="edit-box">
            <div>
              <span style="font-weight: 800">下月估价</span>
            </div>
            <div style="display: flex">
              <div style="flex: 10">
                <PriceGrids :rows="formData.next_quote"/>
              </div>
              <el-button type="primary" :plain="!(formData.quote_source==='next_quote')" icon="el-icon-circle-check"
                         class="check-btn"
                         @click="selectQuote(formData.next_quote,'next_quote')"></el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="" class="edit-box">
            <div>
              <AdjustPricePanel :quote-info.sync="formData.real_quote"
                                :title="`商业化营销中心终价`"
                                :edit-price="true"/>
            </div>
          </el-form-item>
          <el-form-item style="text-align: center" label-width="0px">
            <el-button type="primary" size="small" @click="saveQuote" :disabled="loadingSb" :loading="loadingSb"
                       v-if="userPermissions.indexOf('plc_save_quote')>-1">
              保存
            </el-button>
            <el-button @click="notifyByDing" size="small" v-if="userPermissions.indexOf('plc_save_quote')>-1"
                       :disabled="loadingNotify" :loading="loadingNotify">
              通知定价
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PriceGrids from "@/pages/publication/PriceGrids";
import AdjustPricePanel from "@/pages/publication/adjust/AdjustPricePanel";

const {deepClone} = require("@/utils");

export default {
  name: "RealQuoteEdit",
  components: {AdjustPricePanel, PriceGrids},
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    id: {
      type: [Number, Array],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      formData: {
        id: null,
        quote_source: null,
        current_quote: null,
        next_quote: null,
        real_quote: null,
      },
      loadingSb: false,
      loadingNotify: false,
    }
  },
  // watch: {
  //   id() {
  //     this.getInfo()
  //   }
  // },
  methods: {
    init() {
      // this.$notify.info(`数据加载中...${this.id}`)
      this.getInfo()
    },
    async getInfo() {
      if (this.id) {
        let {info} = await this.$api.getPublicationItemInfo(this.id)
        this.$nextTick(() => {
          let {id, quote_source, current_quote, next_quote, real_quote} = info
          this.formData = {
            id: id,
            quote_source: quote_source,
            current_quote: current_quote,
            next_quote: next_quote,
            real_quote: real_quote,
          }
          this.$forceUpdate()
        })
      }
    },
    async saveQuote() {
      //保存最终价格
      this.loadingSb = true
      let params = {id: this.formData.id, real_quote: this.formData.real_quote}
      if (this.formData.quote_source) {
        params['quote_source'] = this.formData.quote_source
      }
      let id = await this.$api.saveRealQuote(params);
      if (id) {
        this.$emit('saved', this.formData.real_quote);
      }
      this.loadingSb = false

    },
    selectQuote(quotes, quote_source) {
      this.formData.quote_source = quote_source
      this.formData.real_quote = deepClone(quotes)
    },
    async notifyByDing() {
      this.loadingNotify = true
      // this.$notify.warning('定价通知：定价与你的预估价格不同，通知知晓！！！（开放中...）')
      await this.$api.handleRealQuoteNotify(this.formData.id)
      this.$notify.success('通知已发送')
      this.loadingNotify = false
    }
  },
  created() {
    this.getInfo()
  }
}
</script>

<style scoped>
.quote-input {
  margin-bottom: 5px;
}

.edit-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.check-btn {
  margin-left: 10px;
}

.item {
  display: flex;
}

.is-label {
  flex: 3;
}

.item-val {
  flex: 3;
  margin-left: 5px;
}

.item-remark {
  flex: 5;
  margin-left: 5px;
}
</style>
