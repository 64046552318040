<template>
  <div>
    <div v-if="rows">
      <div v-if="rows instanceof Array">
        <el-table size="mini" :data="rows" :show-header="false" border>
          <el-table-column label="报价类目" prop="title" align="center" min-width="100">
          </el-table-column>
          <el-table-column label="报价金额" prop="val" align="center" min-width="120">
            <template slot-scope="{row}">
              <div :class="calcHasNewValClass(row.newVal,row.val)">
                {{ row.val ? moneyFormat(row.val) : '/' }}
                <div v-if="row.remark" style="color: red;">{{ row.remark || '--' }}</div>
              </div>
              <div v-if="applyMoney(row.field, row)">
                <i :class="calcChange(row.newVal,row.val)"></i>
                <span :class="calcNewValClass(row.newVal,row.val)">{{ moneyFormat(row.newVal) }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <div v-if="rows.multi" style="margin-bottom: 2px">
          <el-table size="mini" :data="rows.multi" highlight-current-row border v-if="rows.multi.length">
            <el-table-column label="品类" prop="kind" align="center" min-width="80">
            </el-table-column>
            <el-table-column label="场次" prop="sessions" align="center" min-width="80">
            </el-table-column>
            <el-table-column label="直播费用" prop="lb_fee" align="center" min-width="120">
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-bottom: 2px">
          <el-table size="mini" :data="rows.single" v-if="rows.single.length>0" :show-header="false"
                    highlight-current-row border>
            <el-table-column label="报价类目" prop="title" align="center" min-width="100">
            </el-table-column>
            <el-table-column label="报价金额" prop="val" align="center" min-width="120">
              <template slot-scope="{row}">
                <div :class="calcHasNewValClass(row.newVal,row.val)">
                  {{ row.val ? moneyFormat(row.val) : '/' }}
                  <div v-if="row.remark" style="color: red;">{{ row.remark || '--' }}</div>
                </div>
                <div v-if="applyMoney(row.field, row,'single')">
                  <i :class="calcChange(row.newVal,row.val)"></i>
                  <span :class="calcNewValClass(row.newVal,row.val)">{{ moneyFormat(row.newVal) }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceGrids",
  props: {
    rows: {
      type: [Array, Object],
      default() {
        return []
      }
    },
    applyRecord: {
      type: [Object],
      default() {
        return {};
      }
    },
    field: {
      type: String,
      default() {
        return 'next_quote'
      }
    }
  },
  computed: {
    priceList() {
      return this.applyRecord ? this.applyRecord[this.field] : null
    }
  },
  methods: {
    moneyFormat(val) {
      return this.$utils.numberFormat(val, 0, '.', ',')
    },
    applyMoney(field, item, type) {
      if (type) {
        if (field && this.priceList) {
          let arr = this.priceList[type] || []
          let row = arr.find(val => val.field === field)
          let newVal = row.val || ''
          this.$set(item, 'newVal', newVal)
          return newVal
        }
      } else {
        // return field
        if (field && this.priceList && this.priceList.length > 0) {
          let row = this.priceList.find(val => val.field === field)
          let newVal = row.val || ''
          this.$set(item, 'newVal', newVal)
          return newVal
        }

        return null
      }

    },
    calcChange(newVal, val) {
      if (newVal > val) {
        return 'el-icon-top up'
      } else if (newVal < val) {
        return 'el-icon-bottom down'
      } else
        return ''
    },
    calcNewValClass(newVal, val) {
      if (newVal > val) {
        return 'up'
      } else if (newVal < val) {
        return 'down'
      } else
        return 'equal'
    },
    calcHasNewValClass(newVal, val) {
      if (newVal > val) {
        return 'has'
      } else if (newVal < val) {
        return 'has'
      } else
        return ''
    }
  }
}
</script>

<style scoped>

.price-box {
  /*border: #00feff 1px dashed;*/
  width: 100%;
  line-height: 20px;
  min-height: 20px;
  /*height: 100%;*/
  text-align: left;
}


.price {
  /*flex: 4;*/
  display: flex;
  width: 100%;
  /*text-align: right;*/
  /*font-size: 10px;*/
  vertical-align: middle;
  line-height: 20px;
  /*padding-right: 2px;*/
  /*border: #0C0C0C 1px dashed*/
}

.label {
  flex: 4;
  font-weight: 800;
  /*width: 40%;*/
  border: #bb00ff 1px dashed;
  /*border: #3e0808 1px dashed;*/
  /*text-align: right;*/
  overflow: hidden;
}

.up {
  color: #ff4949;
}

.down {
  color: #13ce66;
}

.has {
  text-decoration: line-through;
}

.equal {
  display: none;
}
</style>
