<template>
    <div>
        <el-card>
            <div slot="header" class="clearfix" v-if="!formData.id">
                <!--        筛选条件-->
                <el-form label-width="90px" :inline="true">
                    <el-form-item label="">
                        <artist-search-custom :type="`plc_data_scope`" @handleSelect="selectArtist"
                                              :show-first-group="false"
                                              style="width: 100%"/>
                    </el-form-item>
                </el-form>
            </div>
            <el-form ref="elForm" :model="formData" :rules="rules" label-width="100px">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span class="is-label">刊例提报基本信息</span>
                        <div v-if="formData.id&&userPermissions.indexOf('plc_item_edit')>-1">
                            <el-button style="float: right; padding: 3px 0" type="text" v-if="!editBase"
                                       @click="editBase = true"><i
                                    class="el-icon-edit-outline"></i>修改
                            </el-button>
                            <el-button style="float: right; padding: 3px 0" type="text" @click="saveBaseBtn" v-else><i
                                    class="el-icon-finished"></i>保存
                            </el-button>
                        </div>
                    </div>
                    <div>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="红人" prop="nickname">
                                    <el-input v-model="formData.nickname" placeholder="红人检索，选择红人（KOL）" readonly>
                                        <el-button type="warning" slot="append" icon="el-icon-refresh"
                                                   @click="getKolPlatformInfo"
                                                   class="link-btn" :loading="loading" :disabled="loading"
                                                   :title="`刷新红人平台信息`"></el-button>
                                        <el-button type="warning" slot="append" icon="el-icon-edit"
                                                   v-if="formData.artist_id"
                                                   @click="goToArtistDetail(formData.artist_id)" class="link-btn"
                                                   :title="`编辑红人平台信息`"></el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="标签">
                                    <el-radio-group v-model="formData.tag">
                                        <el-radio :label="tag.val" v-for="(tag,idx) in tagMap" :key="idx"
                                                  :disabled="!editBase">
                                            {{ tag.label }}
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="刊例月份" label-width="100px" prop="ym">
                                    <template slot="label">
                                        <span style="color: #E6A23C;font-weight: 800">刊例月份</span>
                                    </template>
                                    <span>{{ formData.year }} 年 {{ formData.month }} 月</span>
                                </el-form-item>
                                <el-form-item label="平台" prop="platform_code" label-width="100px">
                                    <div style="display: flex;flex-direction: row">
                                        <el-image style="width: 32px;height: 32px" fit="contain"
                                                  :src="platformInfo.logo_url"
                                                  :alt="platformInfo.name" v-if="platformInfo.logo_url"/>
                                        <div style="line-height: 32px;vertical-align: middle;margin-left: 5px;">
                                            {{ formData.platform_code }} / {{ formData.platform_name }}
                                        </div>
                                    </div>
                                </el-form-item>

                                <el-form-item label="KOL简介">
                                    <el-input v-model="formData.intro" type="textarea" :rows="8" placeholder="请输入KOL简介"
                                              show-word-limit
                                              maxlength="500" disabled/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="头像">
                                    <el-avatar :size="60" :src="formData.cover_pc_url" v-if="formData.cover_pc_url">
                                        <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
                                    </el-avatar>
                                </el-form-item>
                                <el-form-item label="平台ID" label-width="100px" prop="account_id">
                                    <el-input v-model="formData.account_id" show-word-limit maxlength="100" disabled/>
                                </el-form-item>
                                <el-form-item label="所在地区" prop="city">
                                    <el-input v-model="formData.city" show-word-limit maxlength="120" disabled/>
                                </el-form-item>
                                <el-form-item label="经纪人" label-width="100px" prop="agent_name">
                                    <el-input v-model="formData.agent_name" show-word-limit maxlength="100" disabled/>
                                </el-form-item>
                                <el-form-item label="主页链接" prop="home_page_link">
                                    <el-input v-model="formData.home_page_link" placeholder="主页链接" maxlength="200"
                                              disabled>
                                        <el-button slot="append" icon="el-icon-link" title="点击跳转到主页"
                                                   @click="openUrl(formData.home_page_link)"></el-button>
                                    </el-input>
                                </el-form-item>
                                       <el-row>
                            <el-col >
                                <el-form-item label="红人标签" label-width="100px" >
                                    <el-input type="text" v-model="formData.kol_label"
                                              disabled
                                              clearable/>
                                </el-form-item>
                            </el-col>
                            <el-col >
                                <el-form-item label="红人肤质" label-width="100px" >
                                    <el-input type="text" v-model="formData.skin_type"
                                              disabled
                                              clearable/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                            </el-col>
                        </el-row>
                        <el-row v-if="platformType !== 'OFFLINE'">
                            <el-col :span="12">
                                <el-form-item label="当月订单数" label-width="110px" prop="order_total">
                                    <template slot="label">
                <span>当月订单数
                </span>
                                    </template>
                                    <el-input type="text" v-model="formData.order_total"
                                              oninput="value=value.replace(/[^\d^\.^\-]/g,'')"
                                              placeholder="录入确定合作的单数" :disabled="!editBase" clearable>
                                        <el-button type="warning" slot="append" icon="el-icon-refresh"
                                                   @click="syncFromAdSystem"
                                                   class="link-btn" :loading="loading" :disabled="loading"
                                                   :title="`录入确定合作的单数`"></el-button>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="现粉丝数" label-width="100px" prop="fans_total">
                                    <el-input type="text" v-model="formData.fans_total"
                                              oninput="value=value.replace(/[^\d^\.^\-]/g,'')"
                                              placeholder="同步自红人涨粉数据（单位：个）" :disabled="!editBase" clearable>
                                        <el-button type="warning" slot="append" icon="el-icon-refresh"
                                                   @click="getCurrentMonthData"
                                                   class="link-btn" :loading="loading" :disabled="loading"
                                                   :title="`同步红人粉丝数`"></el-button>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="platformType !== 'OFFLINE'">
                            <el-col :span="12">
                                <el-form-item label="下月订单数" label-width="110px" prop="next_order_total">
                                    <template slot="label">
                                        <span>下月订单数</span>
                                    </template>
                                    <el-input type="text" v-model="formData.next_order_total"
                                              oninput="value=value.replace(/[^\d^\.^\-]/g,'')"
                                              placeholder="录入确定合作的单数" :disabled="!editBase" clearable>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="预估粉丝量" label-width="100px" prop="pre_fans_total">
                                    <el-input type="text" v-model="formData.pre_fans_total"
                                              placeholder="下月底预估粉丝量，必填（单位：个）"
                                              oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled="!editBase"
                                              clearable/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="最近广告排期" label-width="110px" prop="last_release_date">
                                    <template slot="label">
                <span>广告排期
                  <el-tooltip class="item" effect="dark" content="最近广告排期：品宣商务类型的档期最后的排期" placement="right">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </span>
                                    </template>
                                    <el-date-picker
                                            v-model="formData.last_release_date"
                                            type="date"
                                            format="yyyy年MM月dd日"
                                            value-format="yyyy-MM-dd"
                                            placeholder="最近广告排期" :disabled="!editBase" clearable>
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                        </el-row>
                 
                        <el-row>
                            <el-col v-if="fieldRange.indexOf('bs_case')>-1">
                                <el-form-item label="商务案例">
                                    <ApeEditor :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify ' +
          '  | removeformat anchor hr |' +
          ' code preview fullscreen'" :init-html="formData.bs_case"
                                               @handleTinymceInput="handleTinymceInput"></ApeEditor>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div>
                            <el-row>
                                <el-col :span="12" v-if="fieldRange.indexOf('zc_total')>-1">
                                    <el-form-item label="赞藏数" prop="zc_total">
                                        <el-input placeholder="赞藏数（万）" v-model="formData.zc_total" :disabled="!editBase"
                                                  oninput="value=value.replace(/[^\d^\.^\-]/g,'')" clearable>
                                            <template slot="append">万</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="fieldRange.indexOf('lb_time')>-1">
                                    <el-form-item label="直播时间">
                                        <el-input placeholder="直播时间：周二或周五 20点" v-model="formData.lb_time"
                                                  :disabled="!editBase" clearable/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="fieldRange.indexOf('lb_case')>-1">
                                    <el-form-item label="直播案例">
                                        <el-input placeholder="直播案例：9.27号抖音首播400万" v-model="formData.lb_case"
                                                  :disabled="!editBase"
                                                  clearable maxlenth="100" show-word-limit/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="fieldRange.indexOf('female_fans_ratio')>-1">
                                    <el-form-item label="女粉比例">
                                        <el-input placeholder="女粉比例：76.13%" v-model="formData.female_fans_ratio"
                                                  :disabled="!editBase"
                                                  oninput="value=value.replace(/[^\d^\.^\-]/g,'')" clearable>
                                            <template slot="append">%</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="fieldRange.indexOf('avg_online')>-1">
                                    <el-form-item label="平均在线">
                                        <el-input placeholder="平均在线：1200" v-model="formData.avg_online"
                                                  :disabled="!editBase" clearable>
                                            <template slot="append">个</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-if="platformType === 'OFFLINE'">
                            <el-divider>
                                线下活动
                            </el-divider>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="全网粉丝" label-width="100px" prop="fans_total">
                                        <el-input type="text" v-model="formData.fans_total"
                                                  oninput="value=value.replace(/[^\d^\.^\-]/g,'')"
                                                  placeholder="全网粉丝" clearable>
                                            <el-button slot="append" icon="el-icon-refresh" :loading="loading"
                                                       @click="getFansData"
                                                       title="更新红人粉丝数"></el-button>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="15">
                                <el-form-item label="" label-width="100px">
                                    <div v-if="formData.fans_state&&formData.fans_state.length>0">
                                        <el-col :span="12" v-for="(item,index) in formData.fans_state" :key="index">
                                            <el-input placeholder="请输入平台粉丝数" v-model="item.fans_count"
                                                      oninput="value=value.replace(/[^\d^\.^\-]/g,'')" clearable>
                                                <template slot="prepend">{{ item.platform_name }}</template>
                                            </el-input>
                                        </el-col>
                                    </div>
                                </el-form-item>
                            </el-row>
                        </div>
                        <!--            <el-divider v-if="extraFields&&extraFields.length>1">其他设置</el-divider>-->
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="开通星图" v-if="extraFields.indexOf('opened_star')>-1">
                                    <el-switch v-model="formData.opened_star" active-value="Y" active-text="是"
                                               inactive-value="N"
                                               inactive-text="否"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="开通橱窗" v-if="extraFields.indexOf('opened_window')>-1">
                                    <el-switch v-model="formData.opened_window" active-value="Y" active-text="是"
                                               inactive-value="N"
                                               inactive-text="否"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="品牌合作人" v-if="extraFields.indexOf('is_partner')>-1">
                                    <el-switch v-model="formData.is_partner" active-value="Y" active-text="是"
                                               inactive-value="N"
                                               inactive-text="否"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="开通购物车" v-if="extraFields.indexOf('opened_cart')>-1">
                                    <el-switch v-model="formData.opened_cart" active-value="Y" active-text="是"
                                               inactive-value="N"
                                               inactive-text="否"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <!--        刊例提报报价信息-->
                <el-card style="margin-top: 10px;">
                    <div slot="header" class="clearfix">
                        <el-row>
                            <el-col :span="8"><span style="font-weight: 800">刊例提报报价信息:{{ platformType }}</span></el-col>
                            <el-col :span="12">
                                <el-link type="primary" @click="refreshPlatformQuote" icon="el-icon-refresh">报价类型
                                </el-link>
                                <el-link :disabled="!formData.platform_code" type="warning" icon="el-icon-document-copy"
                                         @click="copyLastPlatformQuote"
                                         style="margin-left: 20px">
                                    不调价
                                </el-link>
                                <el-link type="danger" @click="clearQuote" icon="el-icon-refresh-right"
                                         style="margin-left: 20px">
                                    调价
                                </el-link>
                            </el-col>
                            <el-col :span="4" v-if="!!formData.id&&userPermissions.indexOf('plc_edit_price')>-1">
                                <el-button style="float: right; padding: 3px 0" type="text" @click="editPrice = true"
                                           v-if="!editPrice">
                                    <i class="el-icon-edit-outline"></i>
                                    {{ formData.status === 2 ? '申请改价' : '修改' }}
                                </el-button>
                                <el-button style="float: right; padding: 3px 0" type="text" @click="saveEdit" v-else><i
                                        class="el-icon-finished"></i>保存
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div>
                        <!--当月价格-->
                        <AdjustPricePanel :quote-info.sync="formData.current_quote" :title="`当月（${currentMonth}）价格`"
                                          :edit-price="editPrice" :platform-type="platformType"/>
                        <el-divider/>
                        <!--下月预估-->
                        <AdjustPricePanel :quote-info.sync="formData.next_quote" :title="`下月（${year}年${month}月）预估`"
                                          :edit-price="editPrice" :platform-type="platformType"/>
                    </div>
                </el-card>
                <div class="submit-btn-row">
                    <el-row>
                        <el-col :span="24" style="text-align: center" v-if="!formData.id">
                            <el-button type="primary" @click="handleConfirm" size="large" class="submit-btn"
                                       :disabled="loadingSb"
                                       :loading="loadingSb" v-if="userPermissions.indexOf('plc_adjust')>-1">
                                <i class="el-icon-check"></i> &nbsp;&nbsp;保存 - 刊例调整
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </el-card>
        <br/>
        <!--        刊例调整说明组件：：数据来自文章管理-->
        <PlcNotice v-if="userPermissions.indexOf('plc_notice')>-1"/>

        <!--    红人匹配   -->
        <ArtistMatch :visible.sync="dialogArtistMatchVisible" :id="formData.artist_id"/>

    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
  import PlatformSelect from '@/pages/platform/components/PlatformSelect'
  import ArtistMatch from '@/pages/setting/artist/ArtistMatch'
  import PlcNotice from '../PlcNotice'
  import EditableCell from '@/components/EditableCell'
  import AdjustPricePanel from '@/pages/publication/adjust/AdjustPricePanel'
  import { deepClone } from '@/utils'
  import ApeEditor from '@/components/ApeEditor'
  import { html2json } from 'html2json'

  export default {
    name: 'CreateEdit',
    props: {
      id: {
        type: [Number, String],
        require: false
      },
      year: {
        type: [Number, String],
        require: true
      },
      month: {
        type: [Number, String],
        require: true
      },
      platformInfo: {
        type: [Object],
        require: true
      },
      editFormData: {
        type: Object,
        default() {
          return {}
        }
      },
      pid: {
        type: [Number, String],
        require: false
      }
    },

    components: {
      AdjustPricePanel,
      EditableCell,
      ArtistMatch,
      ArtistSearchCustom, PlatformSelect, PlcNotice, ApeEditor
    },
    computed: {
      ...mapGetters(['userPermissions']),
      currentMonth() {
        let date = new Date(this.year, this.month - 1)
        date.setMonth(date.getMonth() - 1)
        return date.getFullYear() + '年' + (date.getMonth() + 1) + '月'
      },
      platformType() {
        return this.setting.type || 'ONLINE'
      },
      //可查看的字段范围：不同平台配置不同的字段显示
      fieldRange() {
        let common_fields = this.setting.common_fields || []
        let extra_fields = this.setting.extra_fields || []
        return [...common_fields, ...extra_fields]
      }
    },
    data() {
      return {
        loadingSb: false,
        loadingForm: false,
        loadingOrderTotal: false,
        setting: {},//配置信息
        formData: {
          nickname: '',
          artist_id: '',
          dept_id: '',
          dept_name: '',
          platform_code: this.platformInfo.code,
          platform_name: this.platformInfo.name,
          account_id: '',
          home_page_link: '',
          agent_name: '',
          city: '',
          intro: '',
          bs_case: '',
          year: this.year,
          month: this.month,
          fans_total: null,//当月粉丝数
          order_total: null,//当月订单数
          next_order_total: null,//当月订单数
          last_release_date: null,//最近的广告排期
          pre_fans_total: null,//预计粉丝量
          current_quote: {
            single: [],
            multi: [
              // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
            ]
          },//当月价格
          next_quote: {
            single: [],
            multi: [
              // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
            ]
          },
          zc_total: null,//赞藏数（万）
          lb_time: null,//直播时间
          lb_case: null,//直播案例
          female_fans_ratio: null,//女粉比例
          avg_online: null,//平均在线
          cover: null,
          opened_star: 'N',
          opened_window: 'N',
          is_partner: 'N',
          opened_cart: 'N',
          cover_pc_url: '',
          fans_state: []//各个平台粉数数
          ,
          //案例富文本
          bs_case_rich: '',
          //红人标签
          kol_label:'',
          //红人肤质
          skin_type:'',
        },
        rules: {
          nickname: [{ required: true, message: '红人必选', trigger: 'change' }],
          account_id: [{ required: true, message: '平台ID必填', trigger: 'blur' }],
          home_page_link: [{ required: true, message: '主页链接必填', trigger: 'blur' }],
          agent_name: [{ required: true, message: '负责经纪人必填', trigger: 'blur' }],
          city: [{ required: true, message: '所在地区必填', trigger: 'blur' }],
          // platform_code: [{required: true, message: '平台必选', trigger: 'blur'}],
          zc_total: [{ required: true, message: '请输入赞藏数必填', trigger: 'blur' }],
          fans_total: [{ required: true, message: '请输入当月粉丝数', trigger: 'blur' }],
          order_total: [{ required: true, message: '请输入当月订单数', trigger: 'blur' }],
          next_order_total: [{ required: true, message: '请输入下月订单数', trigger: 'blur' }],
          last_release_date: [{ required: true, message: '最近的广告排期', trigger: 'blur' }],
          pre_fans_total: [{ required: true, message: '下月底预估粉丝量', trigger: 'blur' }],
          current_quote: [{ required: true, message: '当月价格', trigger: 'blur' }]
        },
        searchCondition: {},
        platformRange: [],//可选平台
        platforms: [],//平台信息
        loading: false,
        quoteMap: {},
        // platformType: 'ONLINE',//平台类型:区分线上平台和线下活动，显示不同数据类型
        extraFields: [],//额外字段
        platformQuoteArr: [],//平台报价信息
        needQuote: false,
        dialogArtistMatchVisible: false,//红人匹配
        tagMap: [
          { label: '无', val: '' },
          { label: 'New', val: 'new' }
          // {label: '惠', val: 'benefit'},
          // {label: '荐', val: 'recommend'},
        ],
        editBase: false,//修改基本
        editPrice: false//修改报价
      }
    },
    watch: {},
    methods: {
      //编辑器
      handleTinymceInput(val) {
        this.formData.bs_case = val
        this.formData.bs_case_rich = html2json(val)
      },
      async init() {
        if (this.userPermissions.indexOf('plc_save_quote') > -1) {
          this.tagMap = [
            { label: '无', val: '' },
            { label: 'NEW', val: 'new' },
            { label: '惠', val: 'benefit' },
            { label: '荐', val: 'recommend' }
          ]
        }
        await this.getPlatforms()//平台个数
        // console.log('----- init -----')
        await this.initVal(this.id)
      },
      //初始化-变量
      async initVal(id) {
        let isEdit = !!id// 判断是否有ID，即判断是（有ID）编辑还是（无ID）新增
        this.needQuote = !isEdit
        this.editPrice = !isEdit
        this.editBase = !isEdit
        if (isEdit) {
          await this.getInfo(id)
        } else {
          this.initForm()//初始化表单
          await this.getPlatformSetting(true)//配置信息
          await this.copyLastInfo()//同步上月信息
        }
      },
      initForm() {
        this.formData = {
          pid: this.pid,
          nickname: '',
          artist_id: '',
          dept_id: '',
          dept_name: '',
          platform_code: this.platformInfo.code,
          platform_name: this.platformInfo.name,
          account_id: '',
          home_page_link: '',
          agent_name: '',
          city: '',
          intro: '',
          bs_case: '',
          year: this.year,
          month: this.month,
          fans_total: null,//当月粉丝数
          order_total: null,//当月订单数
          next_order_total: null,//当月订单数
          last_release_date: null,//最近的广告排期
          pre_fans_total: null,//预计粉丝量
          current_quote: {
            single: [],
            multi: [
              // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
            ]
          },//当月价格
          next_quote: {
            single: [],
            multi: [
              // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
            ]
          },
          zc_total: null,//赞藏数（万）
          lb_time: null,//直播时间
          lb_case: null,//直播案例
          female_fans_ratio: null,//女粉比例
          avg_online: null,//平均在线
          cover: null,
          opened_star: 'N',
          opened_window: 'N',
          is_partner: 'N',
          opened_cart: 'N',
          cover_pc_url: '',
          fans_state: [],//各个平台粉数数
          bs_case_rich: '',
            //红人标签
          kol_label:'',
          //红人肤质
          skin_type:'',
        }
      },
      addMultiRow() {
        if (!this.formData.multi)
          this.$set(this.formData, 'multi', [])

        this.formData.multi.push({ kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金' })
      },
      delMultiRow(index) {
        if (!this.formData.multi)
          this.$set(this.formData, 'multi', [])

        this.formData.multi.splice(index, 1)
      },
      async selectArtist(selectInfo) {
        // console.log('si', selectInfo)
        this.formData.nickname = selectInfo.nickname
        this.formData.artist_id = selectInfo.artist_id
        this.formData.dept_id = selectInfo.group_id
        this.formData.dept_name = selectInfo.group_name
        //切换红人-期基本信息清空
        this.formData.home_page_link = ''
        this.formData.account_id = ''
        this.formData.agent_name = ''
        this.formData.city = ''
        this.formData.intro = ''
        this.formData.cover = 0
        this.formData.cover_pc_url = null
        //红人平台信息
        await this.getKolPlatformInfo()
        //平台报价配置信息和额外字段
        // await this.getPlatformSetting(true)
        // this.getCurrentMonthData()  //读取当月粉丝数和订单数
        await this.copyLastInfo()
      },
      //获取平台选择范围
      async getPlatformRange() {
        if (this.formData.artist_id) {
          let { info } = await this.$api.getArtistInfo(this.formData.artist_id)
          this.platformRange = []
          if (info.platforms) {
            let objs = info.platforms
            let codes = Object.keys(objs)
            for (let p in codes) {
              let code = codes[p]
              let val = objs[code]
              if (val === 'Y')
                this.platformRange.push(code)
            }
          }
        }
      },
      //获取平台配置信息
      async getPlatformSetting(needCalc) {
        if (this.formData.platform_code) {
          // this.$notify.info(`读取平台：${ this.formData.platform_code }的报价配置`)
          let { info } = await this.$api.getPlatformSettingInfoByPlatformCode(this.formData.platform_code)
          this.setting = info
          //平台额外字段
          this.extraFields = info.extra_fields || []
          //平台的报价信息
          this.platformQuoteArr = info.platform_quote || []
          if (needCalc) {
            //添加刊例的时候直接组装报价录入表格
            this.calcPlatformQuote()
          }

          //当平台类型为线下平台的时候，需要录入各个平台粉丝数
          // this.platformType = info.type || 'ONLINE'
          if (this.platformType === 'OFFLINE') {
            if (this.formData.fans_state.length === 0) {
              let fansState = []
              this.platforms.forEach((item) => {
                fansState.push({ platform_name: `${item.name}粉丝数`, platform_code: item.code, fans_count: null })
              })
              this.formData.fans_state = fansState
            }
          }
        } else {
          this.$notify.warning(`请先选择平台置！！！`)
        }
      },
      calcPlatformQuote() {
        let platformQuoteArr = this.platformQuoteArr
        if (platformQuoteArr.length > 0) {
          //组装
          if (this.platformType === 'DL') {
            this.formData.current_quote = {
              single: [],
              multi: []
            }
            this.formData.next_quote = {
              single: [],
              multi: []
            }
          } else {
            this.formData.current_quote = []
            this.formData.next_quote = []
          }
          if (platformQuoteArr && platformQuoteArr.length > 0) {
            platformQuoteArr.forEach((item) => {
              if (this.platformType === 'DL') {
                this.formData.current_quote.single.push(Object.assign({}, item, { remark: '' }))
                this.formData.next_quote.single.push(Object.assign({}, item, { remark: '' }))
              } else {
                this.formData.current_quote.push(Object.assign({}, item, { remark: '' }))
                this.formData.next_quote.push(Object.assign({}, item, { remark: '' }))
              }
            })
          }
        } else {
          this.$notify.warning(`未配置报价类型，请通知管理员设置`)
        }
      },
      refreshPlatformQuote() {
        if (!this.formData.platform_code) {
          this.$notify.warning(`请先选择平台置！！！`)
          return
        }
        //刷新报价类型
        this.$confirm('重载报价类型后，需要重写录入报价, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getPlatformSetting(true)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })

      },
      async getKolPlatformInfo() {
        if (this.formData.platform_code && this.formData.artist_id) {
          this.formData.cover = null
          this.formData.cover_pc_url = null
          this.formData.home_page_link = null
          this.formData.account_id = null
          this.formData.agent_name = null
          this.formData.city = null
          this.loading = true
          let { info } = await this.$api.getArtistPlatformInfo({
            code: this.formData.platform_code,
            artist_id: this.formData.artist_id
          })

          this.formData.home_page_link = info.home_page_link
          this.formData.account_id = info.account_id
          this.formData.agent_name = info.agent_name
          this.formData.city = info.city
          this.formData.intro = info.intro
          this.formData.cover = info.cover
          this.formData.kol_label = info.kol_label
          this.formData.skin_type = info.skin_type
          this.formData.cover_pc_url = info.cover_pc_url
          this.loading = false
        }
        // else {
        //   this.$notify.warning(`${this.formData.platform_code}:${this.formData.artist_id}`)
        // }
      },
      async getCurrentMonthData() {
        this.formData.fans_total = null
        this.formData.last_release_date = null
        if (this.formData.platform_code && this.formData.artist_id && this.formData.year && this.formData.month) {
          let { fans_total, last_release_date } = await this.$api.getPublicationCurrentData({
            artist_id: this.formData.artist_id,
            platform_code: this.formData.platform_code,
            year: this.formData.year,
            month: this.formData.month
          })
          this.$nextTick(() => {
            this.formData.fans_total = fans_total
            this.formData.last_release_date = last_release_date
          })
        }
      },
      openUrl(href) {
        window.open(href, '_blank')
      },
      // 响应编辑按钮
      goToArtistDetail(id) {
        const { href } = this.$router.resolve({
          name: 'artist-edit',
          params: { artist_id: id }
        })
        // this.$router.push(this.$route.path + '/' + id + '/edit')
        window.open(href, '_blank')
      },
      handleConfirm() {
        this.$refs['elForm'].validate(valid => {
          if (!valid) return
          this.onSubmit()
        })
      },
      async onSubmit() {
        this.loadingSb = true
        setTimeout(() => {
          this.loadingSb = false
        }, 1000)
        let id = await this.$api.savePublicationItem(this.formData)
        if (id) {
          this.$notify.success('保存成功')
          this.loadingSb = false
          this.$emit('saved')
        }
      },
      async getInfo(id) {
        // this.$notify.info(`ID:${id}`)
        this.loadingForm = true
        let { info } = await this.$api.getPublicationItemInfo(id)
        if (info) {
          //表单数据
          this.formData = info
          //获取平台选择范围
          // await this.getPlatformRange(this.formData.artist_id);
          this.loadingForm = false
          //平台报价配置信息和额外字段
          await this.getPlatformSetting(false)
        }
      },
      async syncFromAdSystem() {
        this.loadingOrderTotal = true
        if (this.formData.platform_code && this.formData.artist_id && this.formData.year && this.formData.month) {
          let param = {
            artist_id: this.formData.artist_id,
            platform_code: this.formData.platform_code,
            year: this.formData.year,
            month: this.formData.month
          }

          let { total, code } = await this.$api.getPublicationOrderTotal(param)
          this.loadingOrderTotal = false
          if (code === 200) {
            if (total != undefined) {
              //同步自广告系统
              this.$confirm(`红人(KOL)【${this.formData.nickname}】的广告订单数为 ${total} (数据来自星广汇系统), 是否同步?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$nextTick(() => {
                  this.formData.order_total = total
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消同步'
                })
              })
            }
          } else if (code === 404) {
            this.$confirm(`红人(KOL)【${this.formData.nickname}】未与星广汇系统匹配， 是否前往匹配?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.dialogArtistMatchVisible = true
              // this.goToArtistDetail(this.formData.artist_id)
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              })
            })
          }
        } else {
          this.$notify.warning('请先选择红人、平台、月份！！！')
          this.loadingOrderTotal = false
        }
      },
      delRow() {
        this.$confirm(`确定删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let info = await this.$api.deletePublicationItem(this.formData.id)
          if (info) {
            this.$notify.success('删除成功')
            this.$emit('deleted')
          }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })

      },
      copyLastInfo() {
        //添加提报的时候同步上月信息：商务案例、当月价格（同步自上月的定价）
        if (!this.formData.id && this.formData.artist_id && this.formData.platform_code) {
          // this.$notify.warning(`【${this.formData.nickname}】当月（${this.currentMonth}）价格正在读取中...`)
          this.copyQuote()
        }
      },
      /**
       * 同步上月定价：不调价
       */
      copyLastPlatformQuote() {
        // console.log('next_quote', this.formData.next_quote)
        this.formData.next_quote = deepClone(this.formData.current_quote)
        // this.formData.current_quote.forEach((item) => {
        //   //下月定价同步
        //   let posIndexNext = this.formData.next_quote.findIndex(value => value.field === item.field)
        //   // console.log('posIndexNext', posIndexNext)
        //   this.$set(this.formData.next_quote[posIndexNext], 'field', item.field)
        //   this.$set(this.formData.next_quote[posIndexNext], 'title', item.title)
        //   this.$set(this.formData.next_quote[posIndexNext], 'val', item.val)
        //   this.$set(this.formData.next_quote[posIndexNext], 'remark', item.remark)
        // })
      },
      async copyQuote() {
        let { info } = await this.$api.getLastPublicationItem(this.formData.artist_id, this.formData.year + '-' + this.formData.month, this.formData.platform_code)
        if (info) {
          //同步商务案例
          if (info.bs_case) {
            this.formData.bs_case = info.bs_case || ''
            this.$notify.success('商务案例已同步')
          }
          //同步上月定价为本月价格
          if (info.real_quote) {
            this.formData.current_quote = deepClone(info.real_quote)
            // let current_quote = this.formData.current_quote
            // let dateType = this.getDataType(info.real_quote)
            // if (dateType === 'object') {
            //
            //   let posIndex = current_quote.single.findIndex(value => value.field === item.field)
            //   this.$set(current_quote.single[posIndex], 'field', item.field)
            //   this.$set(current_quote.single[posIndex], 'title', item.title)
            //   this.$set(current_quote.single[posIndex], 'val', item.val)
            //   this.$set(current_quote.single[posIndex], 'remark', item.remark)
            // }else {
            //   let posIndex = current_quote.findIndex(value => value.field === item.field)
            //   this.$set(current_quote[posIndex], 'field', item.field)
            //   this.$set(current_quote[posIndex], 'title', item.title)
            //   this.$set(current_quote[posIndex], 'val', item.val)
            //   this.$set(current_quote[posIndex], 'remark', item.remark)
            // }

            this.$notify.success('报价已同步')
          } else {
            this.$notify.warning('上月未定价！')
          }
        } else {
          this.$notify.warning('上月未提报！')
        }
      },
      clearQuote() {
        this.copyQuote()
        //清空报价
        let dataType = this.getDataType(this.formData.next_quote)
        if (dataType === 'array') {
          this.formData.next_quote.forEach((item) => {
            item.val = null
            item.remark = null
          })
        } else {
          this.formData.next_quote.multi = []
          this.formData.next_quote.single.forEach((item) => {
            item.val = null
            item.remark = null
          })
        }
        this.msgSuccess('已清空下月预估报价')
      },
      getDataType(data) {
        if (data) {
          if (typeof data === 'object') {
            if (data instanceof Array) {
              return 'array'
            } else {
              return 'object'
            }
          }
        }
        return null
      },
      //各个平台粉数数量获取
      async getFansData() {
        this.loading = true
        if (this.formData.artist_id && this.formData.year && this.formData.month) {
          let { data_fans } = await this.$api.getFansData({
            artist_id: this.formData.artist_id,
            year: this.formData.year,
            month: this.formData.month
          })
          // console.log('data_fans', data_fans)

          let fans_total = 0
          if (data_fans && data_fans.length > 0) {
            data_fans.forEach((item) => {
              let index = this.formData.fans_state.findIndex(value => value.platform_code === item.platform_code)
              if (index > -1) {
                // console.log(`${ index } item`, item)
                this.formData.fans_state[index]['fans_count'] = item.fans_count || 0
                fans_total += item.fans_count * 1
              }
            })
            this.formData.fans_total = fans_total
          } else {
            this.$notify.warning('平台粉丝信息为空')
          }
        }

        this.loading = false
      },
      //申请改价
      async applyEditPrice() {
        let { info } = await this.$api.applyEditPricePublicationItem({ id: this.formData.id })
        if (info)
          this.msgSuccess('已申请改价')
      },
      //修改保存-刊例报价
      async saveEdit() {
        this.editPrice = false
        //修改报价
        let data = {
          id: this.formData.id,
          current_quote: this.formData.current_quote,
          next_quote: this.formData.next_quote
        }
        let info = await this.$api.saveQuoteItem(data)
        if (info)
          this.msgSuccess('保存成功')

        this.$emit('updated')
      },
      //修改保存-刊例基本信息
      async saveBaseBtn() {
        this.editBase = false
        //修改基本信息
        let data = Object.assign({}, this.formData)
        delete data.current_quote
        delete data.next_quote
        delete data.real_quote
        let info = await this.$api.saveBasePublicationItem(data)
        if (info)
          this.msgSuccess('保存成功')

        this.$emit('updated')
      },
      async getPlatforms() {
        let { list } = await this.$api.getPlatforms()
        this.platforms = list
      }
    },
    created() {
      this.getPlatforms()//平台个数
    }
  }
</script>

<style scoped>
    .submit-btn-row {
        text-align: center;
        margin-top: 10px;
    }

    .link-btn {
        /*color: #ff3176 !important;*/
        font-size: 16px !important;
        /*background-color: #ff3176!important;*/
        /*border: 1px solid #ff3176!important;*/
    }

    .is-label {
        display: inline-block;
        font-weight: 600;
        text-align: right;
        margin-right: 5px;
        font-size: 0.8rem;
    }

    .intro, .bs_case {
        /**
        首行缩进
         */
        text-indent: 2em;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
</style>
