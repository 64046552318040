import { render, staticRenderFns } from "./PlcDetail.vue?vue&type=template&id=131d5eb1&scoped=true&xmlns%3Ael-col=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./PlcDetail.vue?vue&type=script&lang=js&"
export * from "./PlcDetail.vue?vue&type=script&lang=js&"
import style0 from "./PlcDetail.vue?vue&type=style&index=0&id=131d5eb1&prod&scoped=true&lang=css&"
import style1 from "./PlcDetail.vue?vue&type=style&index=1&id=131d5eb1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131d5eb1",
  null
  
)

export default component.exports